<template>
  <div class="w course-box">
    <div class="inputbox">
      <el-input
        placeholder="请输入您要搜索的课程"
        v-model="albumParam.albumName"
        class="input-with-select"
        clearable
      >
        <template slot="prepend"
          ><img class="searchimg" src="./images/search.png" alt=""
        /></template>
        <el-button slot="append" @click="getAlbumList">搜索</el-button>
      </el-input>
    </div>
    <div class="classlist">
      <ul>
        <!-- <li class="active">电影</li>
        <li class="active">电视剧</li>
        <li>纪录片</li> -->
        <li
          v-for="(sub, subindex) in subjectList"
          :key="sub.id"
          :class="activeParentSub == sub.id ? 'active' : ''"
          @click="changeSubject(sub.id, subindex, sub.uiType)"
        >
          {{ sub.subjectName }}

          <img
            v-if="sub.children.length > 0 && activeParentSub == sub.id"
            class="upicon"
            src="./images/up.png"
            alt=""
          />
          <img
            v-if="sub.children.length > 0 && activeParentSub != sub.id"
            class="upicon"
            src="./images/down.png"
            alt=""
          />
        </li>
      </ul>
    </div>
    <div
      class="classlisttwo"
      v-if="
        subjectList[activeParentIndex] &&
        subjectList[activeParentIndex].children.length > 0
      "
    >
      <ul>
        <li
          v-for="child in subjectList[activeParentIndex].children"
          :key="child.id"
          :class="activeChildSub == child.id ? 'active' : ''"
          @click="changeSubjectByChild(child.id)"
        >
          {{ child.subjectName }}
        </li>
      </ul>
    </div>
    <!-- <div class="classlisttwo" v-if="activeParentIndex == 0">
      <ul>
        <li
          v-for="(albums, index) in albumList"
          :key="albums.id"
          :class="activeChildSub == albums.id ? 'active' : ''"
          @click="changeAlbum(albums.id, albums.albumName, index)"
        >
          {{ albums.albumName }}
        </li>
      </ul>
    </div> -->
    <!-- <div class="courselist" v-if="uiType == 'icon'" v-loading="loading">
      <div
        class="courseitem"
        v-for="classes in classList"
        :key="classes.id"
        @click="courseDetail()"
      >
        <img :src="albumList[activeAlbumIndex].coverId" alt="" />

        <div class="oneline">
          <h3 class="titlefont3">{{ classes.className }}</h3>
          <p class="zhengwen3">
            {{ classes.title }}
          </p>
        </div>
      </div>
    </div> -->
    <div
      class="courselist"
      v-if="uiType == 'list' || uiType == 'icon'"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255,255,255,0.6)"
    >
      <div
        class="courseitem"
        v-for="albums in albumList"
        :key="albums.id"
        @click="courseDetail(albums)"
      >
        <img :src="albums.coverId" alt="" />

        <div class="oneline">
          <p class="titlefont3 elstitle">{{ albums.albumName }}</p>
          <p class="zhengwen3 elszhengwen" v-html="albums.remark"></p>
        </div>
      </div>
    </div>
    <div
      class="courselist"
      v-if="uiType == 'pdf'"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255,255,255,0.6)"
    >
      <PdfPage :albumList="albumList"></PdfPage>
    </div>

    <el-pagination
      :page-size="albumParam.pageSize"
      :current-page="albumParam.pageNum"
      hide-on-single-page
      background
      layout="prev, pager, next"
      :total="albumTotal"
      prev-text="上一页"
      next-text="下一页"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>


<script>
import {
  subjectTree, albumList, pdfList, classList
} from "@/api"
import PdfPage from "./pdfpage"
export default {

  name: "Find",
  components: {
    PdfPage,
  },
  data () {
    return {
      loading: true,
      input3: '',
      subjectList: [],
      activeParentSub: 0,
      activeParentIndex: 0,
      activeChildSub: 0,
      activeAlbum: 0,
      activeAlbumIndex: 0,
      albumParam: {
        pageNum: 1,
        pageSize: 10,
        albumName: '',
        subjectId: '',
        // isTop: 'N',
        // isRecomm: 'N'
      },
      albumList: [],
      albumTotal: 0,
      uiType: 'list',//list pdf icon三种类型
      // classList: [],
      // classTotal: 0,
      // classQuery: {
      //   albumId: 0,
      //   albumName: '',
      //   pageNum: 1,
      //   pageSize: 10,
      // },
    }
  },
  mounted () {
    this.getSubjectTree()


  },
  methods: {
    // getClassList () {
    //   classList(this.classQuery).then((d) => {
    //     if (d.code == 200) {
    //       this.classList = d.rows
    //       this.classTotal = d.total
    //     }
    //   })

    // },
    // textsub (a) {
    //   console.log(a, 'a')
    //   return a ? a.substring(0, 28) + "..." : ""
    // },
    changePage (page) {
      this.albumParam.pageNum = page
      if (this.uiType == 'list') {

        this.getAlbumList()
      }
      if (this.uiType == 'pdf') {
        this.getPdfList()
      }

    },
    courseDetail (albums) {
      // id, albumName
      // this.$router.push({ name: 'FindDetail', query: { albumId: albums.id, albumName: albums.albumName } })
      this.$router.push({ name: 'FindDetail', params: { albums } })
    },
    changeSubject (id, index, uiType) {
      this.albumParam.pageNum = 1
      this.uiType = uiType
      this.albumParam.subjectId = id

      this.activeParentSub = id
      this.activeParentIndex = index
      this.activeChildSub = 0
      if (this.subjectList[index].children && this.subjectList[index].children.length > 0) {
        this.activeChildSub = this.subjectList[index].children[0].id
        this.albumParam.subjectId = this.activeChildSub
      }

      if (uiType == 'pdf') {
        this.getPdfList()

      } else {
        this.getAlbumList()
      }


    },
    getPdfList () {
      this.albumParam.pageSize = 8
      pdfList(this.albumParam).then((d) => {
        if (d.code == 200) {
          this.albumList = d.rows
          this.albumTotal = d.total
        }
      })
    },
    changeSubjectByChild (id) {
      this.albumParam.subjectId = id
      this.albumParam.pageNum = 1
      this.activeChildSub = id
      this.getAlbumList()
    },
    //电影二级导航展示的专辑
    changeAlbum (id, name, index) {
      this.classQuery.albumId = id
      this.classQuery.albumName = name
      this.activeAlbumIndex = index
      this.activeAlbum = id
    },
    getSubjectTree () {
      subjectTree({ subjectType: 'discover' }).then((d) => {
        if (d.code == 200) {
          this.subjectList = d.data
          console.log(this.subjectList, 'this.subjectList')
          if (this.subjectList[0].children && this.subjectList[0].children.length > 0) {
            this.activeParentSub = this.subjectList[0].id
            this.activeChildSub = this.subjectList[0].children[0].id
            this.albumParam.subjectId = this.activeChildSub
          } else {
            this.activeParentSub = this.subjectList[0].id
            this.activeChildSub = ''
            this.albumParam.subjectId = this.activeParentSub
          }

          this.getAlbumList()
        }

      })

    },
    getAlbumList () {
      this.albumParam.pageSize = 10
      // this.albumParam.subjectId = this.activeParentSub
      albumList(this.albumParam).then((d) => {
        if (d.code == 200) {
          // console.log(d, 'xxx')
          this.loading = false,
            this.albumList = d.rows
          this.albumTotal = d.total
          // this.classQuery.albumId = this.albumList[0].id
          // this.classQuery.albumName = this.albumList[0].albumName
          // this.getClassList()
        }

      })

    }

  }

}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
.upicon {
  width: 24px;
  height: 24px;
}
.elstitle {
  line-height: 1.5;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis;
}
.elszhengwen {
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.course-box {
  // width: 100%;
  min-height: 94vh;
  padding: 130px 0 80px;
  .inputbox {
    text-align: center;
  }
  .el-input {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .el-input-group {
    max-width: 744px;
    width: 90%;
    height: 60px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(20px);
  }
  /deep/.el-input-group__append {
    border: none;
    width: 111px;
    height: 52px;
    border-radius: 12px;
    background: rgba(67, 207, 124, 1);
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  /deep/.el-input-group__prepend {
    border: none;
    background-color: transparent;
  }
  /deep/.el-input__inner {
    height: 100%;
    border: none;

    font-size: 20px;
    font-weight: 400;
    color: rgba(29, 29, 31, 1);

    // color: rgba(170, 170, 170, 1);
  }
  .classlist {
    // display: flex;
    // justify-content: space-between;
    height: 32px;
    margin-top: 35px;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        height: 32px;
        margin-right: 20px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        display: flex;
        align-items: center;
      }
      .active {
        border-radius: 6px;
        padding: 0 10px;
        font-weight: 500;
        color: rgba(67, 207, 124, 1);
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .classlisttwo {
    margin-top: 25px;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        margin-right: 20px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
      }
      .active {
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        padding: 2px 8px 4px 8px;
        color: rgba(67, 207, 124, 1);
      }
    }
  }
  .courselist {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    // height: 580px;
    // min-height: 72vh;
    .courseitem {
      width: 284px;
      height: 250px;
      cursor: pointer;

      margin: 20px 8px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(20px);
      border: 2px solid rgba(255, 255, 255, 1);
      position: relative;
      img {
        width: 284px;
        height: 160px;
        position: absolute;
        top: -2px;
        left: -2px;
      }
      // .coursedes {

      //   border-top: none;
      // }
      .oneline {
        margin-top: 164px;
        padding: 0 16px;
        max-height: 74px;
        overflow: hidden;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        // text-overflow: ellipsis;
      }
    }
  }
  /deep/.el-pagination {
    text-align: center;
    margin: 20px 0 40px;
  }
  /deep/.el-pagination.is-background .btn-next,
  /deep/.el-pagination.is-background .btn-prev,
  /deep/.el-pagination.is-background .el-pager li {
    height: 32px;
    line-height: 32px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 1);
    backdrop-filter: blur(20px);

    font-size: 14px;
    font-weight: 400;
    color: rgba(119, 119, 119, 1);

    padding: 0 12px;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    height: 32px;
    background: rgba(67, 207, 124, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
  }
}
</style>