<template>
  <div class="pdflist">
    <div class="pdfitem" v-for="albums in albumList" :key="albums.id">
      <div class="pdfcover">
        <img src="./images/cover.png" alt="" />
      </div>
      <div class="pdfcontent">
        <div>
          <p class="font16400 black1 ontitle">{{ albums.pdfName }}</p>
          <!-- <p class="font14400 grey1 secondtitle">
            【结藤社独家】结藤社2023年专四考试真题答案
          </p> -->
          <div class="taglist">
            <!-- <div class="tag-item tag_four">专四</div> -->
            <div v-if="albums.isVip == 'N'" class="tag-item tag_free">免费</div>
            <div v-if="albums.isNew == 'Y'" class="tag-item tag_new">NEW</div>
          </div>
        </div>
        <div class="buttons">
          <div
            class="selbutton"
            @click="pafurl(albums.pdfDownloadStatus, albums.pdfUrl)"
          >
            <!-- <a v-show="albums.pdfDownloadStatus" :href="baseUrl+albums.pdfUrl" download="filename.pdf">下载PDF</a> -->
            <span class="font14400 black1">立即下载</span>
          </div>
          <div
            class="selbutton"
            @click="pafurl(albums.pdfDownloadStatus, albums.pdfUrl)"
          >
            <span class="font14400 black1">本地查看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['albumList'],
  data () {
    return {
      baseUrl: window.__baseUrl__,
    }
  },
  methods: {
    pafurl (type, url) {
      if (type == 'Y') {
        // window.location.href = this.baseUrl + url
        window.open(this.baseUrl + url, '_blank')
      } else {
        // this.$message.warning("")
        this.$message({
          message: '您还没有权限！',
          type: 'warning'
        })
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import url(../../../assets/css/common.css);
.pdflist {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  .pdfitem {
    width: 368px;
    height: 230px;
    margin: 10px 2px;
    padding: 16px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(20px);
    display: flex;
    .pdfcover {
      width: 140px;
      height: 198px;
      opacity: 1;
      background: url(https://img.js.design/assets/img/641a91e15f39ee09e5e5be66.png#ad6ee996793130453a30486b7e92b66a);
      box-shadow: 0px 2px 4px rgba(170, 170, 170, 0.25);
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .taglist {
      display: flex;
      margin-top: 5px;
      .tag-item {
        width: 40px;
        height: 24px;
        border-radius: 4px;
        margin-right: 10px;

        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 24px;
      }
      .tag_four {
        background: linear-gradient(
          135deg,
          rgba(255, 138, 20, 1) 0%,
          rgba(254, 197, 101, 1) 100%
        );
      }
      .tag_new {
        background: linear-gradient(
          90deg,
          rgba(76, 224, 215, 1) 0%,
          rgba(81, 159, 232, 1) 100%
        );
      }
      .tag_vip {
        background: linear-gradient(
          90deg,
          rgba(224, 76, 76, 1) 0%,
          rgba(232, 161, 82, 1) 100%
        );
      }
      .tag_free {
        background: linear-gradient(
          90deg,
          rgba(224, 76, 76, 1) 0%,
          rgba(232, 161, 82, 1) 100%
        );
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      .selbutton {
        width: 88px;
        height: 30px;
        text-decoration-line: underline;
        cursor: pointer;
        line-height: 27px;
        text-align: center;
        opacity: 1;
        border-radius: 4px;
        background: rgba(238, 238, 238, 1);
      }
    }
    .secondtitle {
      margin-top: 5px;
    }
    .pdfcontent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>